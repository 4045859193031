import React, { useRef, useState } from "react"
import "flickity/css/flickity.css"
import styled from "@emotion/styled"
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: false,
  wrapAround: true,
  prevNextButtons: true,
}

const GiftImageSlider = ({ data }) => {
  return typeof window !== "undefined" ? (
    <Carousel>
      <Flickity
        options={flickityOptions}
        className={"carousel"}
        static
        reloadOnUpdate
      >
        {data.map((item, index) => {
          return (
            item && (
              <ExpertBox key={index.toString()}>
                <Image className="lazyload" data-src={item} />
              </ExpertBox>
            )
          )
        })}
      </Flickity>
    </Carousel>
  ) : null
}

const ExpertBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 550px) {
    height: auto;
  }
`

const ExpertImage = styled.div`
  width: 100%;
`

const Image = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: cover;
  height: 100%;

  @media (max-width: 550px) {
    object-fit: contain;
    height: auto;
  }
`

const Carousel = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0px;

  .carousel {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .flickity-viewport {
    display: flex;
    width: 100%;
    height: 100% !important;

    @media (max-width: 850px) {
    }
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 25px;
    height: 25px;
    border: 2px solid #111;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button.previous {
    left: 5px;
  }
  .flickity-prev-next-button.next {
    right: 5px;
  }
`

export default GiftImageSlider
