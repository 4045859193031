const filters = {
  price: {
    0: { products: [0], description: "- de 55€" },
    1: { products: [0, 1, 2, 3, 4], description: "jusqu’à 130€" },
    2: { products: [5], description: "+ de 130€" },
  },
  categories: {
    0: { products: [1, 3], description: "sportif.ve" },
    1: { products: [0, 2, 3], description: "geek" },
    2: { products: [0, 3], description: "stressé.e" },
    3: { products: [0, 2, 3], description: "souvent avachi.e" },
    4: { products: [3, 4], description: "jardinier.ère" },
    5: { products: [3, 4], description: "bricoleur.se" },
    6: { products: [0, 2, 3], description: "accro à la télé" },
  },
}

export const product = {
  0: { handle: "gift-card-loop", description: "LOOP" },
  1: { handle: "gift-card-lynefit", description: "LYNEFIT" },
  2: { handle: "gift-card-lyneup", description: "LYNEUP" },
  3: { handle: "gift-card", description: "GIFTCARD" },
  4: { handle: "gift-card-lynehome", description: "LYNEHOME" },
}

const checkNotInArray = (array, item) => {
  if (array.indexOf(item) === -1) return true
  else return false
}

export const productFilter = ({ cat = {}, price = {} } = {}) => {
  var categoryItems = []
  var priceItems = []
  var finalProduct = []

  let allCatValues = Object.values(cat)
  let allPriceValues = Object.values(price)
  let anyCatTrue = false
  let anyPriceTrue = false
  allCatValues.forEach(el => {
    if (el) anyCatTrue = true
  })
  allPriceValues.forEach(el => {
    if (el) anyPriceTrue = true
  })
  if (!anyPriceTrue && !anyCatTrue) return product

  // OR for categories
  Object.keys(cat).forEach(item => {
    if (cat[item]) {
      filters["categories"][item]["products"].forEach(element => {
        if (checkNotInArray(categoryItems, element)) categoryItems.push(element)
      })
    }
  })

  // OR for price
  Object.keys(price).forEach(item => {
    if (price[item]) {
      filters["price"][item]["products"].forEach(element => {
        if (checkNotInArray(priceItems, element)) priceItems.push(element)
      })
    }
  })

  // AND for price and category
  let productCompared = []
  if (categoryItems.length > 0 && priceItems.length > 0) {
    productCompared = categoryItems.filter(element =>
      priceItems.includes(element)
    )
  } else if (categoryItems.length === 0) {
    productCompared = priceItems
  } else if (priceItems.length === 0) {
    productCompared = categoryItems
  }
  productCompared.forEach(
    item => product[item] && finalProduct.push(product[item])
  )

  // console.log(categoryItems, priceItems, finalProduct, "PRODUCTS")

  return finalProduct
}

export const priceProducts = () => {
  return process.env.GATSBY_CURRENCY_TYPE === "usd"
    ? 149
    : process.env.GATSBY_CURRENCY_TYPE === "cad"
    ? 189
    : process.env.GATSBY_CURRENCY_TYPE === "gbp"
    ? 99
    : 129
}
